<template>
  <div class="検索方法">
    <div class="検索方法-label unselectable" :class="{ active: 検索方法 === 'キーワード' }" @click="click検索方法('キーワード')">キーワードから検索する</div>
    <div class="検索方法-label unselectable" :class="{ active: 検索方法 === '性別年代' }" @click="click検索方法('性別年代')">性別や年代から検索する</div>
  </div>

  <div class="search-キーワード-wrapper" v-if="検索方法 === 'キーワード'">
    <div class="search-topline">
      <p class="search-topline-title">キーワード</p>
      <!-- <p>
        アーティスト追加依頼は<a class="link-underline" target="_blank" href="#">こちら</a>&nbsp;<i class="icon icon-external-link"></i>
      </p> -->
      <p>
        アーティスト追加依頼は<a class="link-underline" href="mailto:info.grooveforceengagement@sme.co.jp?subject=GROOVEFORCE ENGAGEMENT お問い合わせ">こちら</a
        >&nbsp;<i class="icon icon-email"></i>
      </p>
    </div>
    <div class="input-wrapper">
      <span class="icon-search"></span>
      <input
        type="search"
        id="filterString"
        v-model="filterString"
        placeholder="アーティスト名、もしくはコンテンツ名を入力"
        @focus="searchFocus()"
        @blur="searchBlur()"
      />
      <button class="search-button" id="searchButton" type="button" @click="searchButton()">検索する</button>
    </div>
    一覧から探す場合は<a class="link-underline" @click="openArtistList()">こちら</a>
  </div>

  <SearchAgeGender
    v-if="検索方法 === '性別年代'"
    :set-favorite="setFavorite"
    @pageMove="pageMove"
    @clickArtistFavorite="updateArtistFavorite"
    @setSearchArtistHistory="setSearchArtistHistory"
  />

  <!-- 最近の検索 -->
  <div v-if="searchSubWindow === 'searchFocus'" class="search-history">
    <div class="search-history-title">最近の検索(最新10件)</div>
    ※フォローボタンをクリックするとホーム画面に常時表示されます。
    <div class="search-history-artist-list artist-list" v-if="searchHistoryArtist.length">
      <a class="artist-item" @click.self="pageMove(artistInfo)" v-for="artistInfo in searchHistoryArtist" :key="artistInfo">
        {{ artistInfo.artist_name }}
        <i class="icon icon-person_add_alt_1" @click="updateArtistFavorite(artistInfo.artist_code)" v-if="!artistInfo.isfavorite"></i>
        <i class="icon icon-person favorite" @click="updateArtistFavorite(artistInfo.artist_code)" v-if="artistInfo.isfavorite"></i>
      </a>
    </div>
    <div v-else>なし</div>
  </div>

  <!-- 検索中 -->
  <div v-if="searchSubWindow === 'searchInput' && 検索方法 === 'キーワード'" class="searching-contents">
    <div class="searching-contents-count">{{ searchArtist.length }} 件ヒットしました。</div>
    ※フォローボタンをクリックするとホーム画面に常時表示されます。
    <div class="searching-contents-artist-list artist-list">
      <a class="artist-item" @click.self="pageMove(artistInfo)" v-for="artistInfo in searchArtist" :key="artistInfo">
        {{ artistInfo.artist_name }}
        <i class="icon icon-person_add_alt_1" @click="updateArtistFavorite(artistInfo.artist_code)" v-if="!artistInfo.isfavorite"></i>
        <i class="icon icon-person favorite" @click="updateArtistFavorite(artistInfo.artist_code)" v-if="artistInfo.isfavorite"></i>
      </a>
    </div>
  </div>

  <!-- 検索結果 -->
  <div v-if="searchSubWindow === 'searchButton' && 検索方法 === 'キーワード'" class="searching-results-contents">
    <template v-if="searchArtist.length === 0">
      <div class="searching-results-contents-title">検索結果</div>
      <div class="searching-results-contents-count">{{ filterString }}に一致する情報は見つかりませんでした。</div>
      <div class="searching-results-contents-request">
        <!-- アーティスト追加依頼は<a class="link-underline" target="_blank" href="#">こちら&nbsp;<i class="icon icon-external-link"></i></a> -->
        アーティスト追加依頼は<a class="link-underline" href="mailto:info.grooveforceengagement@sme.co.jp?subject=GROOVEFORCE ENGAGEMENT お問い合わせ">こちら</a
        >&nbsp;<i class="icon icon-email"></i>
      </div>
    </template>
    <template v-else>
      <div class="searching-results-contents-title">検索結果</div>
      <div class="searching-results-contents-count">{{ searchArtist.length }} 件ヒットしました。</div>

      <div name="artist-list" class="card-area">
        <template v-for="artistInfo in searchArtist" :key="artistInfo.artist_code">
          <ArtistCard
            :artist-info="artistInfo"
            @pageMove="pageMove"
            @clickArtistFavorite="updateArtistFavorite(artistInfo.artist_code)"
            @setSearchArtistHistory="setSearchArtistHistory"
          />
        </template>
      </div>
    </template>
  </div>

  <FavoriteArtist
    :favorite-artist-list="favoriteArtistList"
    @pageMove="pageMove"
    @updateArtistFavorite="updateArtistFavorite"
    @setSearchArtistHistory="setSearchArtistHistory"
    v-if="searchSubWindow !== 'searchFocus' && searchSubWindow !== 'searchInput'"
  />
  <ContentsCard
    title="データ更新情報"
    :chart-data="{ dummy: 'dummy' }"
    component-type="UpdateInfo"
    :view-all-data="false"
    v-if="searchSubWindow !== 'searchFocus' && searchSubWindow !== 'searchInput'"
  />

  <!-- アーティスト一覧 -->
  <div class="overlayView viewArtistList" v-if="viewArtistList" @click.self="closeOverlayView()">
    <ArtistList
      :artist-list-page="artistListPage"
      @pageMove="pageMove"
      @updateArtistFavorite="updateArtistFavorite"
      @closeOverlayView="closeOverlayView"
      @setSearchArtistHistory="setSearchArtistHistory"
    />
  </div>
</template>

<script>
import FavoriteArtist from '@/components/Home/FavoriteArtist.vue';
import ContentsCard from '@/components/ContentsCard/ContentsCard.vue';
import ArtistList from '@/components/Home/ArtistList.vue';
import ArtistCard from '@/components/Home/ArtistCard';
import SearchAgeGender from '@/components/Home/SearchAgeGender';

export default {
  name: 'HomeView',
  inject: ['userAttribute', 'provideArtistList'],
  components: {
    FavoriteArtist,
    ContentsCard,
    ArtistList,
    ArtistCard,
    SearchAgeGender,
  },
  data() {
    return {
      検索方法: 'キーワード',

      filterString: '',
      favoriteCookieList: [],
      searchHistoryList: [],

      searchSubWindow: '',
      viewArtistList: false,
    };
  },
  created() {
    this.favoriteCookieList = JSON.parse(localStorage.getItem('artist_favoritelist')) || [];
    this.searchHistoryList = JSON.parse(localStorage.getItem('searchhistorylist')) || [];
    this.$writeAccessLog();
  },
  computed: {
    artistListPage() {
      return this.setFavorite(this.provideArtistList);
    },
    favoriteArtistList() {
      return this.createFavoriteArtistList(this.artistListPage);
    },
    searchArtist() {
      if (this.検索方法 === 'キーワード') {
        const artistlist = this.setFavorite(this.provideArtistList).filter(
          (e) =>
            (e.artist_name || '').toLowerCase().indexOf(this.filterString.toLowerCase()) > -1 ||
            (e.artist_name_kana || '').indexOf(this.filterString) > -1 ||
            (e.artist_name_roman || '').toLowerCase().indexOf(this.filterString.toLowerCase()) > -1
        );
        return artistlist;
      }
      return [];
    },
    searchHistoryArtist() {
      return this.searchHistoryList
        .map((code) => this.provideArtistList.find((artist) => artist.artist_code === code))
        .filter((artist) => artist !== undefined)
        .reverse();
    },
  },
  methods: {
    click検索方法(検索方法) {
      this.filterString = '';
      this.検索方法 = 検索方法;
    },

    // お気に入り関連
    setFavorite(list) {
      if (!list.length) return [];
      const self = this;
      return list.map(function (e) {
        if (self.favoriteCookieList.find((v) => e.artist_code == v)) {
          e.isfavorite = true;
        } else {
          e.isfavorite = false;
        }
        return e;
      });
    },
    createFavoriteArtistList(list) {
      let resultList = list.filter((e) => e.isfavorite === true);
      resultList.sort((a, b) => {
        return this.favoriteCookieList.indexOf(a.artist_code) - this.favoriteCookieList.indexOf(b.artist_code);
      });
      return resultList;
    },
    updateArtistFavorite(artist_code) {
      if (this.favoriteCookieList.includes(artist_code)) {
        this.favoriteCookieList = this.favoriteCookieList.filter((e) => e !== artist_code);
      } else {
        this.favoriteCookieList.push(artist_code);
      }
      localStorage.setItem('artist_favoritelist', JSON.stringify(this.favoriteCookieList));
    },

    // アーティスト一覧オーバーレイ
    openArtistList() {
      this.viewArtistList = true;
    },
    closeOverlayView() {
      this.viewArtistList = false;
    },

    // 検索関連
    searchFocus() {
      if (this.filterString.length === 0) {
        this.searchSubWindow = 'searchFocus';
      }
    },
    searchBlur() {
      if (this.filterString.length || document.activeElement === document.getElementById('searchButton')) return;
      const self = this;
      setTimeout(function () {
        self.searchSubWindow = '';
      }, 300);
    },
    searchButton() {
      if (this.filterString.length === 0) return;
      this.searchSubWindow = 'searchButton';
    },
    setSearchArtistHistory(artist_code) {
      this.searchHistoryList = [...new Set([...this.searchHistoryList, artist_code].reverse())].reverse().slice(-10);
      // this.searchHistoryList = [...this.searchHistoryList, artist_code].slice(-10);
      localStorage.setItem('searchhistorylist', JSON.stringify(this.searchHistoryList));
    },

    // ページ遷移関連
    pageMove(artistInfo) {
      let url = '/artist-analytics';
      if (artistInfo.type === 'サイト') url = '/site-analytics';
      else if (artistInfo.type === 'ソニーグループ') url = '/sonygroup-analytics';
      else if (artistInfo.type === 'アニメ') url = '/anime-analytics';
      else if (artistInfo.type === 'ゲーム') url = '/game-analytics';
      else if (artistInfo.fanclub_only) url = '/fanclub-analytics';
      url += '?artist_code=' + artistInfo.artist_code;
      this.setSearchArtistHistory(artistInfo.artist_code);
      location.href = url;
    },
    firstPage(artistInfo) {
      let url = '/artist-analytics';
      if (artistInfo.fanclub_only) url = '/fanclub-analytics';
      return url;
    },
  },
  watch: {
    filterString: function (val) {
      if (val.length === 0) {
        if (document.activeElement === document.getElementById('filterString')) {
          this.searchSubWindow = 'searchFocus';
        } else {
          this.searchSubWindow = '';
        }
      } else {
        this.searchSubWindow = 'searchInput';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.検索方法 {
  display: flex;
  justify-content: space-between;
  .検索方法-label {
    width: 50%;
    padding: 12px 0;
    text-align: center;
    font-size: var(--font-size-middle2);
    font-weight: bold;
    color: var(--primary-color);
    background-color: white;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    transition: 0.5s;
    &.active {
      color: white;
      background-color: var(--primary-color);
    }
    &:not(.active):hover {
      background-color: var(--group01-1-color);
      cursor: pointer;
    }
  }
}
.search-キーワード-wrapper {
  text-align: right;
  .search-topline {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .search-topline-title {
      font-weight: bold;
      font-size: 1.8rem;
      color: var(--primary-color);
    }
    .icon-external-link,
    .icon-email {
      color: var(--gray-01-color);
      font-size: 14px;
      vertical-align: top;
    }
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--gray-02-color);
    border-radius: 4px;
    margin-bottom: 8px;
    .icon-search {
      position: absolute;
      display: inline-block;
      margin-top: -2px;
      margin-left: 1rem;
      font-size: 2.2rem;
      color: var(--gray-02-color);
    }
    input {
      display: inline-block;
      width: 100%;
      // height: 42px;
      padding: 0 1rem 0 4rem;
      font-size: 1.6rem;
      line-height: 2.6;
      border: none;
      border-radius: 4px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .search-button {
      width: 160px;
      height: 42px;
      border: none;
      font-weight: bold;
      color: white;
      background-color: var(--primary-color);
      cursor: pointer;
    }
  }
}

.searching-contents,
.search-history {
  padding: 16px;
  font-weight: bold;
  color: var(--primary-color);
  background-color: var(--gray-04-color);
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  .searching-contents-count {
    font-size: 18px;
  }
  .search-history-title {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 16px;
  }
}

.searching-results-contents {
  color: var(--primary-color);
  .searching-results-contents-title {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 16px;
  }
  .card-area {
    width: var(--contents-width);
    display: grid;
    grid-template-columns: repeat(auto-fit, 233px);
    gap: 32px 16px;
    padding: 12px 9px;
  }
}
.searching-results-contents-request {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: fit-content;
  margin-top: 16px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: var(--primary-color);
  border-radius: 24px;
  a {
    color: white;
    &.link-underline {
      text-decoration-color: white !important;
    }
  }
  .icon-external-link,
  .icon-email {
    font-size: 16px;
    margin-top: -8px;
  }
}

.artist-list {
  display: flex;
  flex-wrap: wrap;
  gap: 18px 12px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0 20px;
  .artist-item {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 4px 16px;
    padding-right: 4px;
    color: var(--primary-color);
    background-color: white;
    border: 2px solid var(--primary-color);
    border-radius: 16px;
    .icon {
      display: grid;
      place-content: center;
      height: 22px;
      width: 32px;
      text-align: center;
      margin-left: 16px;
      font-size: 16px;
      color: var(--primary-color);
      background-color: white;
      border: 1.4px solid var(--primary-color);
      border-radius: 11px;
      &.favorite {
        color: white;
        background-color: var(--primary-color);
      }
    }
  }
}
</style>
