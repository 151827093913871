<template>
  <template v-if="!userAttribute.email && !viewSpinner.state">
    <LoginPage />
  </template>
  <template v-else-if="userAttribute.email && state.isLoadingUserInfo && !userAttribute.isPasswordExpired">
    <HeaderBar />
    <div class="container">
      <SideMenu class="side-menu" />
      <div class="contents">
        <router-view />
      </div>
    </div>
    <FooterBar />
  </template>
  <LoaderSpinner v-if="viewSpinner.state" />

  <CognitoLoginPasswordChange
    class="overlayView"
    sub-title="パスワードの有効期限が過ぎたため、変更が必要です。"
    :username="userAttribute.email"
    :is-password-expired="userAttribute.isPasswordExpired"
    @closePasswordChangeModal="false"
    v-if="userAttribute.isPasswordExpired"
  />
</template>

<script>
import { defineComponent, provide, reactive, toRef, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import HeaderBar from './components/HeaderBar.vue';
import SideMenu from './components/SideMenu.vue';
import FooterBar from './components/FooterBar.vue';
import LoginPage from './views/LoginPage.vue';
import LoaderSpinner from '@/components/LoaderSpinner.vue';
import CognitoLoginPasswordChange from '@/components/CognitoLoginPasswordChange.vue';

export default defineComponent({
  components: {
    HeaderBar,
    SideMenu,
    FooterBar,
    LoginPage,
    LoaderSpinner,
    CognitoLoginPasswordChange,
  },
  setup() {
    const route = useRoute();
    const currentComponent = getCurrentInstance();

    /////////////
    // 状態の定義
    const state = reactive({
      isLoadingUserInfo: false,
      artistList: [],
      menuList: [],
      demograSuffix: '',
    });
    provide('provideMenuList', toRef(state, 'menuList'));
    provide('provideArtistList', toRef(state, 'artistList'));
    provide('provideDemograSuffix', toRef(state, 'demograSuffix'));

    const viewSpinner = reactive({
      state: false,
    });

    provide('viewSpinner', viewSpinner);
    const userAttribute = reactive({
      // 認証情報
      email: '',
      authProvider: '', // 認証先 ('cognito' or 'azure')
      jwtToken: '',
      exp: 0, // トークンの有効期限
      isPasswordExpired: false, // パスワードの有効期限が切れているか
      companyName: '',
      smeGroupFlag: false,
    });
    const userAttributeRefs = toRefs(userAttribute);
    provide('userAttribute', userAttribute);

    const artistSelectWordMap = {
      '/anime-analytics': { source: 'アニメ作品を選択', target: '比較作品を選択' },
      '/game-analytics': { source: 'ゲーム作品を選択', target: '比較作品を選択' },
      '/sonygroup-analytics': { source: '商品・サイトを選択', target: '比較商品・サイトを選択' },
      '/site-analytics': { source: 'サイトを選択', target: '比較サイトを選択' },
      default: { source: 'アーティストを選択', target: '比較アーティストを選択' },
    };
    const artistSelectWord = artistSelectWordMap[location.pathname] || artistSelectWordMap['default'];

    const initializeArtistAndTitle = (type) => ({
      artistObj: { artist_code: '', artist_name: artistSelectWord[type] },
      musicList: [],
      musicTitle: '',
    });
    const artistAndTitle = reactive({
      source: initializeArtistAndTitle('source'),
      target: initializeArtistAndTitle('target'),
    });
    provide('artistAndTitle', artistAndTitle);

    /////////////
    // コンポーネントがマウントされた後に初期化を実行
    onMounted(() => {
      initialize();
    });

    // ユーザー情報とアーティスト情報の初期化
    const initialize = async () => {
      try {
        viewSpinner.state = true;
        const email = await currentComponent.proxy.$getUserAttribute();
        if (!email) throw new Error('Email not found');
        if (await getUserInfo()) {
          checkUrlQueryArtist(route);
          sortArtistList();
        } else {
          throw new Error('error: getUserInfo');
        }
      } catch (error) {
        redirectToRoot();
      } finally {
        state.isLoadingUserInfo = true;
        viewSpinner.state = false;
      }
    };

    // メソッドの定義...
    const redirectToRoot = () => {
      if (location.pathname !== '/') {
        location.assign('/');
      }
    };

    const getUserInfo = async () => {
      // ユーザーに属したメニュー、アーティストの取得
      try {
        const results = await currentComponent.proxy.$fetch('/common/user-access-info', {});
        if (results) {
          state.menuList = results.menu ?? [];
          state.artistList = results.artist ?? [];
          state.demograSuffix = results.demogra_suffix ?? '';
          userAttribute.companyName = results.user_attribute?.user_company_name ?? '';
          userAttribute.smeGroupFlag = results.user_attribute?.sme_group_flag ?? false;
          return true;
        }
      } catch (error) {
        console.error('An error occurred while fetching user info:', error);
      }
      return false;
    };

    const sortArtistList = () => {
      if (!state.artistList.length) return;
      state.artistList.sort((a, b) => a.artist_name_kana.localeCompare(b.artist_name_kana, 'ja', { numeric: true }));
    };

    const checkArtistList = (artist_code, scope = 'source') => {
      const typeMap = {
        '/artist-analytics': ['アーティスト'],
        '/anime-analytics': ['アニメ'],
        '/game-analytics': ['ゲーム'],
        '/sonygroup-analytics': ['ソニーグループ'],
        '/site-analytics': ['サイト'],
        '/goods-analytics': ['アーティスト', 'アニメ', 'ゲーム', 'サイト', 'ソニーグループ'],
      };
      const type = scope === 'source' ? typeMap[location.pathname] || '' : [];

      let artistObj = state.artistList.find((e) => {
        if (type.length) {
          return e.artist_code === artist_code && type.includes(e.type);
        } else {
          return e.artist_code === artist_code;
        }
      });

      if (artistObj) {
        artistAndTitle[scope].artistObj = artistObj;
        if (scope === 'source') currentComponent.proxy.$updatePageTitle(artistObj.artist_name);
      } else {
        updateArtistObj('', scope);
        if (scope === 'source') updateArtistObj('', 'target');
      }
    };
    const checkUrlQueryArtist = (route) => {
      const { artist_code, artist2_code } = route.query;
      if (artist_code) {
        checkArtistList(artist_code, 'source');
      }

      const sourceArtistCode = artistAndTitle.source.artistObj.artist_code;
      const analyticsPaths = ['/artist-analytics', '/anime-analytics', '/game-analytics', '/site-analytics', '/sonygroup-analytics', '/goods-analytics'];
      if (artist2_code && (sourceArtistCode === '' || !analyticsPaths.includes(location.pathname))) {
        updateArtistObj('', 'target');
      } else if (artist2_code) {
        checkArtistList(artist2_code, 'target');
      }
    };

    const findAndUpdateArtist = (artist_name, scope) => {
      const artistObj = state.artistList.find((e) => e.artist_name === artist_name);
      if (artistObj?.artist_code) {
        return artistObj;
      } else {
        return { artist_code: '', artist_name: artistSelectWord[scope] };
      }
    };

    const updateUrlQueryParameters = () => {
      const params = new URLSearchParams();
      params.append('artist_code', artistAndTitle.source.artistObj?.artist_code);
      if (artistAndTitle.target.artistObj?.artist_code) {
        params.append('artist2_code', artistAndTitle.target.artistObj?.artist_code);
      }
      const pathnameAndQuery = location.pathname + '?' + params.toString() + location.hash;
      history.replaceState({}, '', pathnameAndQuery);
    };

    const updateArtistObj = (artist_name, scope = 'source') => {
      if (scope === 'source') currentComponent.proxy.$updatePageTitle(artist_name);

      if (artistAndTitle[scope].artistObj.artist_name !== artist_name) {
        artistAndTitle[scope].artistObj = findAndUpdateArtist(artist_name, scope);
        // URL にアーティストコードの付与・更新
        const pathname = location.pathname;
        const excludedPaths = ['/live-hall-visitor-analytics', '/trends-', '/report-list', '/manage-'];
        if (pathname !== undefined && !excludedPaths.some((path) => pathname.startsWith(path))) {
          updateUrlQueryParameters();
        }
        // アーティストが更新されると楽曲のクリア
        updateMusicList([], scope);
      }
    };
    provide('updateArtistObj', updateArtistObj);

    const updateMusicList = (musicList, scope = 'source') => {
      artistAndTitle[scope].musicList = musicList;
      artistAndTitle[scope].musicTitle = '';
    };
    provide('updateMusicList', updateMusicList);

    const updateMusicTitle = (musicTitle, scope = 'source') => {
      artistAndTitle[scope].musicTitle = musicTitle;
    };
    provide('updateMusicTitle', updateMusicTitle);

    return {
      ...toRefs(state),
      state,
      viewSpinner,
      menuList: state.menuList,
      artistList: state.artistList,
      ...userAttributeRefs,
      userAttribute: userAttribute,
      artistAndTitle: artistAndTitle,
      updateArtistObj,
      updateMusicList,
      updateMusicTitle,
    };
  },
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  height: 100%;
  width: var(--container-width);
  margin-inline: auto;
  .side-menu {
    width: var(--side-menu-width);
  }
  .contents {
    width: var(--contents-wrapper-width);
  }
}
</style>
