<template>
  <section class="card shadow" :class="{ reserve: reserve, half: half, oneThird: oneThird }" v-if="isDataAvailable">
    <div class="title-line">
      <div class="title">
        {{ title
        }}<template v-if="tooltip.length"
          ><Popper class="help-tool-tip" placement="top" :arrow="true"
            ><i class="icon-question-circle"></i><template #content><div v-html="tooltip"></div></template></Popper
        ></template>
      </div>
      <a class="link-viewall" v-if="detailType.length" @click="openDetail()">全ての結果を見る&nbsp;&nbsp;〉</a>
    </div>

    <keep-alive>
      <component :is="chartComponent" :title="title" :chart-data="chartData" :param="param"></component>
    </keep-alive>

    <div class="footer" v-if="(nNumber.length || updateAt.length) && viewN">
      <div class="left" v-if="nNumber.length">
        <div class="n-number">
          信頼度
          <div class="dataReliability" v-html="dataReliability"></div>
        </div>
        <div class="n-number">n数 {{ nNumber }}</div>
      </div>
      <div v-else></div>
      <div class="right" v-if="updateAt.length">更新：{{ updateAt }}</div>
    </div>
    <div class="overlayView" v-if="reserve">準備中</div>
  </section>
</template>

<script>
import { ref, markRaw } from 'vue';
import Popper from 'vue3-popper';

export default {
  name: 'ContentsCard',
  components: {
    Popper,
  },
  props: {
    reserve: { type: Boolean, default: false },
    half: { type: Boolean, default: false },
    oneThird: { type: Boolean, default: false },
    componentType: { type: String, default: '' },
    detailType: { type: String, default: '' },
    title: { type: String, default: '' },
    chartData: { type: Object, default: () => ({}) },
    chartDetailData: { type: Object, default: () => ({}) },
    param: { type: Object, default: () => ({}) },
    updateAt: { type: String, default: '' },
    viewN: { type: Boolean, default: true },
    tooltip: { type: String, default: '' },
  },
  emits: ['openDetail'],
  data() {
    return {
      chartComponent: ref(null),
    };
  },
  computed: {
    isDataAvailable() {
      const isNonEmptyArray = this.chartData instanceof Array && this.chartData.length !== 0;
      const isNonEmptyObject = this.chartData instanceof Object && Object.keys(this.chartData).length !== 0;

      if ((isNonEmptyArray || isNonEmptyObject) && this.componentType !== null) {
        this.loadComponent();
        return true;
      }
      return false;
    },
    nNumber() {
      let n = '';
      if (this.chartData instanceof Array && this.chartData.length !== 0) {
        if ('n' in this.chartData[0]) {
          n = this.chartData[0]?.n.toLocaleString();
        }
      }
      return n;
    },
    dataReliability() {
      const rectBlack = '<svg width="8px" height="8px"><rect width="8" height="8" style="fill:#333" /></svg>';
      const rectWhite = '<svg width="8px" height="8px"><rect width="8" height="8" style="fill:white;stroke:#333;stroke-width:2" /></svg>';
      let dataReliability = '';
      if (this.chartData instanceof Array && this.chartData.length !== 0) {
        switch (true) {
          case this.chartData[0]?.n == 0:
            dataReliability = rectWhite + '&nbsp;' + rectWhite + '&nbsp;' + rectWhite;
            break;
          case this.chartData[0]?.n < 100:
            dataReliability = rectBlack + '&nbsp;' + rectWhite + '&nbsp;' + rectWhite;
            break;
          case this.chartData[0]?.n < 400:
            // dataReliability = '<span style="color:#333">■■</span><span style="color:white">□</span>';
            dataReliability = rectBlack + '&nbsp;' + rectBlack + '&nbsp;' + rectWhite;
            break;
          default:
            dataReliability = rectBlack + '&nbsp;' + rectBlack + '&nbsp;' + rectBlack;
        }
      }
      return dataReliability;
    },
  },
  methods: {
    async loadComponent() {
      // コンポーネントを動的に読み込む
      try {
        const componentModule = await import(`@/components/ContentsCard/${this.componentType}.vue`);
        this.chartComponent = markRaw(componentModule.default);
      } catch (error) {
        console.error('Failed to load component:', error);
      }
    },
    openDetail() {
      if (this.reserve) return;
      const data = Object.keys(this.chartDetailData).length === 0 ? this.chartData : this.chartDetailData;
      this.$emit('openDetail', this.detailType, this.title, data);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  // position: relative;
  background-color: white;
  border: 1px solid var(--gray-04-color);
  border-radius: 8px;
  line-height: 1;
  width: 100%;
  height: fit-content;
  &.reserve {
    position: relative;
    .link-viewall {
      cursor: not-allowed;
    }
  }
  &.half {
    width: var(--contents-tile-half-width);
  }
  &.oneThird {
    width: var(--contents-tile-onethird-width);
  }

  .title-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: var(--primary-color);
    border-radius: 8px 8px 0 0;
    height: 48px;
    padding: 8px 6px 8px 25px;
    .title {
      display: flex;
      align-items: center;
      font-size: var(--font-size-middle);
      font-weight: bold;
      text-wrap: nowrap;
    }
    .link-viewall {
      padding: 8px;
      color: white;
      text-wrap: nowrap;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin: 16px;
    // margin-top: 24px;
    color: var(--primary-color);
    font-size: var(--font-size-base);
    .left {
      display: flex;
      gap: 16px;
      .n-number {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        background-color: var(--gray-04-color);
        border-radius: 4px;
        .dataReliability {
          font-size: 10px;
          margin-left: 8px;
          margin-top: 1px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }
}

.overlayView {
  z-index: 1;
  position: absolute;
  top: 48px;
  height: calc(100% - 48px);
  font-size: 24px;
  font-weight: bold;
  border-radius: 0 0 8px 8px;
}
</style>
